<template lang="pug">
div.bg-gray
  div.pr-5.thirstlevel(style="top:100px")
    v-row.flex-center
      v-col.pr-0
        bread-crumbs( :paramCOD="$route.params.paramCOD")
      v-col.col-filters.col-filters-big-home.pt-0.pb-0
        div.filters
          div.filter.filtroDate
            range-date
  div
    div.gridFull
      v-row.headTitleGrid
        v-col.titleGrid(md="8")
          span #[translate Restaurant ranking]
        v-col(md="4")
          div.filters
            span.downloadExcel.mr-5(@click="downloadExcelCustom()")
              img(:src="imgCommons.download" width="10")
            div.filter
              span.labelFilter #[translate Tipo de Consumo]:
              multi-select.inline-block(:options="surveyTypes" @checked="onCheckSurveyTypes")
      v-row
        v-col#tableRanking(md="12")
          div.contentTableRanking.row.ma-0
            div(style="width: 100%; font-size: 12px; padding-bottom: 1rem; display: grid; grid-template-columns: 3rem 1fr 1fr 1fr")
              div.d-flex.items-center.gap-0_5em.pl-2.border-bottom-1.pb-1
                span #

              div.d-flex.items-center.gap-0_5em.border-bottom-1.pb-1
                span #[translate Name]
                img(:src="imgCommons.order" @click="sortdata('-name')")
              div.d-flex.items-center.gap-0_5em.border-bottom-1.pb-1
                translate(v-if="level.company && zoneColumnEnabled") Marca
                translate(v-if="(level.brand || level.zone) && zoneColumnEnabled") Zona
              div.d-flex.items-center.justify-center.gap-0_5em.border-bottom-1.pb-1
                span #[translate Nº de Encuestas]
                img(:src="imgCommons.order" @click="sortdata('-total_surveys')")

              template(v-for="(item, index) in itemsRanking")
                div.text-14.py-3.border-bottom-1.pl-2
                  p.mb-0 {{index + 1}}
                div.text-14.py-3.border-bottom-1
                  p.mb-0.link-text( @click="goItemNextLevel(item,true)") {{ item.name }}
                div.text-14.py-3.border-bottom-1
                  p(v-if="zoneColumnEnabled").mb-0 {{ item.level.name }}
                div.text-14.py-3.border-bottom-1
                  p.mb-0.text-center {{ item.total_surveys }}
            p.ml-3(v-if="!itemsRanking") #[translate No data]
</template>

<script>
import RangeDate from '@/components/filters/RangeDates.vue'
import MultiSelect from '@/components/filters/Multipleselect.vue'
import SrvSatisfaction from '@/services/ranking-satisfaction.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import BreadCrumbs from '@/components/common/Breadcrumbs.vue'

export default {
  mixins: [commonMixins],
  data() {
    return {
      surveyTypes: [
        { value: 1, text: 'Local', key: 'room' },
        { value: 2, text: 'Corporativo', key: 'corporate' },
        { value: 3, text: 'Delivery', key: 'delivery' },
      ],
      surveyTypesSelected: [],
      page: 1,
      itemsRanking: [],
      itemsPerPage: 25,
      nextScrollUrl: null,
      nextCall: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'pos',
        },
        { text: '', value: 'name' },
        { text: this.$gettext('No. of Questionnaires'), value: 'numberQuestions', align: 'center' },
        { text: this.$gettext('Satisfaction'), value: 'pointsSatisfaction', align: 'center' },
        { text: this.$gettext('Repetition'), value: 'pointsRepetition', align: 'center' },
        { text: this.$gettext('Recommendation'), value: 'pointsRecomendation', align: 'center' },
        { text: this.$gettext('No. of Comments'), value: 'numberComments', align: 'center', sortable: false, },
        { text: this.$gettext('Positive'), value: 'percentPlus', align: 'center', sortable: false, },
        { text: this.$gettext('Negative'), value: 'percentNegative', align: 'center', sortable: false, },
      ],
      ordering: '-total_surveys'
    }
  },
  components: { MultiSelect, RangeDate, BreadCrumbs },
  created() {
    this.$store.commit(types.UPDATE_SECTION, 3)
    this.getRanking();
  },
  mounted() {
    this.scroll()
  },
  computed: {
    zoneColumnEnabled() {
      return this.level.company || this.level.brand || this.level.zone
    }
  },
  methods: {
    downloadExcelCustom() {
      let params = { ordering: this.ordering }
      this.downloadExcel(params)
    },
    // METODO QUE EMITE EL COMPONENTE MULTISELECT
    onCheckSurveyTypes(val) {
      this.surveyTypesSelected = val
      this.$store.commit(types.SATISFACTION_FILTER_SURVEY_TYPES_SELECTED, val)
      this.getRanking()
    },
    // OBTENEMOS EL RANKING
    getRanking: async function (nextUrl = null, ordering = '-total_surveys') {
      if (!nextUrl) {
        const res = await SrvSatisfaction.getSatisfactionRanking(ordering)
        if (res.status === 200) {
          this.itemsRanking = res.data.results
          this.nextScrollUrl = res.data.next
          this.$store.commit(types.SATISFACTION_RANKING, res.data)
          this.loading = false
        }
      } else {
        const res = await SrvSatisfaction.getSatisfactionRankingNext(nextUrl)
        if (res.status === 200) {
          res.data.results.forEach(element => {
            this.itemsRanking.push(element)
          });
          this.nextScrollUrl = res.data.next
          this.$store.commit(types.SATISFACTION_RANKING, res.data)
          this.nextCall = false
        }
      }
    },
    scroll() {
      window.onscroll = () => {
        if (this.$route.name === 'HealthyPokeRankingHome') {
          var body = document.body, html = document.documentElement
          var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
          height = parseInt(height, 10) - 1
          let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height || parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0), 10) + 1 == height || (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height + 1
          if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
            this.nextCall = true
            this.getRanking(this.nextScrollUrl)
          }
        }
      }
    },
    sortdata(ordering) {
      if (ordering === this.ordering && ordering !== 'total_surveys') {
        ordering = ordering.substring(1)
      } else if (ordering === this.ordering && ordering === 'total_surveys') {
        ordering = '-' + ordering
      }
      this.ordering = ordering
      this.getRanking(null, this.ordering)
    }
  },
  watch: {
    filterApply: function () {
      this.getRanking()
    }
  },
}
</script>

<style lang="scss" scoped>
#tableRanking {
  padding: 2px;
}
</style>
